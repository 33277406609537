<template>
    <div class="teachingHardDialog">
        <el-dialog title="关联行政班" width="80%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-row>
                <el-col :span="2">
                    <span>专业：</span>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="searchForm.professionId" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in professionList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <span>入学年：</span>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="searchForm.year" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in yearList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-input v-model="searchForm.searchText" autocomplete="off"></el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="info" size ="mini" icon="search" @click='loadHardClassList'>查询</el-button>
                </el-col>
            </el-row>
            <el-row style="margin-top: 20px;">
                <el-col :span="11">
                    <el-table v-loading="loading" :data="hardClassData" style="width: 100%" align='center'>
                        <el-table-column prop="name" label="名称"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                        <el-table-column prop="operation" align='center' label="操作" >
                            <template slot-scope='scope'>
                                <el-button icon='edit' size="mini" @click='add2TeachingClass(scope.row)'>加入</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="11" :offset="2">
                    <el-table v-loading="loading" :data="selHardClassData" style="width: 100%" align='center'>
                        <el-table-column prop="hard_class_name" label="名称"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="hard_class_year" label="入学年" align='center' ></el-table-column>
                        <el-table-column prop="operation" align='center' label="操作">
                            <template slot-scope='scope'>
                                <el-button icon='edit' size="mini" type="danger" @click='deleteTeachingClass(scope.row)'>移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'teachingHardDialog',
        data(){
            return {
                formLabelWidth: '120px',
                dialogVisible:false, loading: false,
                hardClassData: [], selHardClassData: [],
                professionList: [], yearList: [],
                teachingClassId: null,
                searchForm:{ pageNo: 1, pageSize: 200, totalCount: 0, searchText: null, schoolId: null, professionId: null, year: null,},
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadYearList();
            this.loadProfessionList();
	    },
        props: {
            teachingClass: {
                type: Object
            }
        },
        watch: {
            teachingClass: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        let that = this;
                        this.teachingClassId = n.id
                        this.loadSelHardClassList(function () {
                            that.loadHardClassList();
                        });
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('ok');
            },
            loadYearList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-5); i<(year+2); i++){
                    this.yearList.unshift(""+i);
                }
            },
            loadProfessionList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclProfession', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.professionList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            add2TeachingClass(row){

                let p = { licenceId: this.userInfo.licenceId, teachingClassId: this.teachingClassId, hardClassId: row.id}
                let param = { controllerName: 'sclTeachingHard', methodName: '/saveOrUpdate', param: p};
                let that = this;
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('teachingHard', code);
                    if (code == 0) {
                        this.loadSelHardClassList(function () {
                            that.loadHardClassList();
                        });
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });

            },
            deleteTeachingClass(row){
                let search = { licenceId: this.userInfo.licenceId, id: row.id};
                let param = { controllerName: 'sclTeachingHard', methodName: '/delete', param: search};
                let that = this;
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    if (code == 0) {
                        this.loadSelHardClassList(function () {
                            that.loadHardClassList();
                        });
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            loadHardClassList(){

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    schoolId: this.userInfo.schoolId, professionId: mUtils.searchText(this.searchForm.professionId),
                    year: mUtils.searchText(this.searchForm.year)};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        if(this.selHardClassData && this.selHardClassData.length > 0){
                            for (let i=0; i<this.selHardClassData.length; i++){
                                let d = this.selHardClassData[i];
                                for (let j=0; j<list.length; j++){
                                    let t = list[j];
                                    if(d.hard_class_id == t.id){
                                        list.splice(j, 1);
                                        break;
                                    }
                                }
                            }
                        }
                        this.hardClassData = list;
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            loadSelHardClassList(callback){

                let search = { licenceId: this.userInfo.licenceId, teachingClassId: this.teachingClassId};
                let param = { controllerName: 'sclTeachingHard', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.selHardClassData = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .teachingHardDialog{

    }


</style>


