<template>
    <div class="teachingClassDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="teachingClassForm" ref="teachingClassDialogForm" :rules="teachingClassRules">
                <el-form-item label="班名"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="teachingClassForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="学年学期"  prop="acadyearTerm" :label-width="formLabelWidth">
                    <el-select v-model="teachingClassForm.acadyearTerm" filterable placeholder="请选择">
                        <el-option key="" label="无" value=""></el-option>
                        <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态：" prop="status" :label-width="formLabelWidth">
                    <el-radio-group v-model="teachingClassForm.status">
                        <el-radio :label="'00'">正常</el-radio>
                        <el-radio :label="'01'">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="所属课程"  prop="courseId" :label-width="formLabelWidth">
                    <el-select v-model="teachingClassForm.courseId" filterable placeholder="请选择">
                        <el-option key="" label="无" value=""></el-option>
                        <el-option v-for="item in courceList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="教师"  prop="teacherId" :label-width="formLabelWidth">
                    <el-select v-model="teachingClassForm.teacherId" filterable placeholder="请选择" style="width: 100%">
                        <el-option v-for="item in teacherList" :key="item.id" :label="item.nick_name+ '(' +item.name + ')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="助教"  prop="teahcer02Id" :label-width="formLabelWidth">
                    <el-select v-model="teachingClassForm.teahcer02Id" filterable placeholder="请选择" style="width: 100%">
                        <el-option key="" label="无" value=""></el-option>
                        <el-option v-for="item in teacherList" :key="item.id" :label="item.nick_name+ '(' +item.name + ')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注"  prop="remark" :label-width="formLabelWidth">
                    <el-input v-model="teachingClassForm.remark" type="textarea"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'teachingClassDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                teacherList: [], courceList: [], acadyearTermList: [],
                teachingClassForm:{ id: null, licenceId: null, name: null, status: '00', courseId: null, teacherId: null, teahcer02Id: null,
                    schoolId: null, acadyearTerm: null, remark: null, },
                teachingClassRules: {
                    name: [{ required: true, message: '请输入教学班名称', trigger: 'blur' }],
                    status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
                    courseId: [{ required: true, message: '请选择课程', trigger: 'blur' }],
                    acadyearTerm: [{ required: true, message: '请输入学年学期', trigger: 'blur' }],
                    teacherId: [{ required: true, message: '请选择教师', trigger: 'blur' }],

                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadCourceList();
            this.loadTeacherList();
            this.loadAcadyearTermList();
	    },
        props: {
            teachingClass: {
                type: Object
            }
        },
        watch: {
            teachingClass: {
                deep: true, immediate:true,
                handler(n, o){

                    if(n){
                        this.teachingClassForm = { id: n.id, licenceId: n.licence_id, status: n.status, name: n.name, courseId: n.course_id, teacherId: n.teacher_id,
                            schoolId: n.school_id, teahcer02Id: n.teahcer02_id, acadyearTerm: n.acadyear_term, remark: n.remark };
                        if(n.id == null) this.myTitle = '新建教学班'; else this.myTitle = '编辑教学班';
                    }else{
                        this.teachingClassForm = { id: null, licenceId: null, name: null, status: '00', courseId: null, teacherId: null, teahcer02Id: null,
                            schoolId: null, acadyearTerm: null, remark: null,};
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){
                console.log(this.teachingClassForm);
                let that = this;
                this.$refs.teachingClassDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.teachingClassForm.licenceId = this.userInfo.licenceId;
                        this.teachingClassForm.schoolId = this.userInfo.schoolId;
                        let param = { controllerName: 'sclTeachingClass', methodName: '/saveOrUpdate', param: this.teachingClassForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('teachingClass', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该教学班已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                this.acadyearTermList.push((year-1) + '-' + year + '第二学期');
                this.acadyearTermList.push((year-1) + '-' + year + '第一学期');
                this.acadyearTermList.push((year) + '-' + (year+1) + '第二学期');
                this.acadyearTermList.push((year) + '-' + (year+1) + '第一学期');
            },
            loadCourceList(){

                let search = { schoolId: this.userInfo.schoolId, licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclCource', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {

                    if (data.code == 0) {
                        this.courceList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadTeacherList(){

                let search = { schoolId: this.userInfo.schoolId, licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclTeacher', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {

                    if (data.code == 0) {
                        this.teacherList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .teachingClassDialog{

    }


</style>


