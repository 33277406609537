<template>
    <div class="teachingClassList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="学年学期"  >
                    <el-select v-model="searchForm.acadyearTerm" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="课程">
                    <el-select v-model="searchForm.courseId" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in courseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="工号、账号、姓名或学科"  @keyup.enter.native='loadTeachingClassList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadTeachingClassList'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='openTeachingClass(null)'>添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                <el-table-column prop="name" label="名称"  width="150" align='center' ></el-table-column>
                <el-table-column prop="acadyear_term" label="学年学期" width="150" align='center' ></el-table-column>
                <el-table-column prop="status" label="状态"  align='center' :formatter="formatterStatus" ></el-table-column>
                <el-table-column prop="cource_name" label="课程名称" align='center' ></el-table-column>
                <el-table-column prop="school_name" label="课程所属单位" align='center' ></el-table-column>
                <el-table-column prop="teaching_school_name" label="开课单位" align='center' ></el-table-column>
                <el-table-column prop="teacher_name" label="教师名称" align='center' ></el-table-column>
                <el-table-column prop="teacher02_name" label="助教" align='center' ></el-table-column>
                <el-table-column prop="hard_class_count" label="行政班数" align='center' ></el-table-column>
                <el-table-column prop="student_count" label="学生人数" align='center' >
                    <template slot-scope='scope'>
                        <el-link type="primary" @click="studentList(scope.row)">{{scope.row.student_count}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" align='center' label="操作" width="300">
                    <template slot-scope='scope'>
                        <el-button icon='edit' size="mini" @click='openUpload(scope.row)'>导入学生</el-button>
                        <el-button icon='edit' size="mini" @click='openTeachingHard(scope.row)'>配置行政班</el-button>
                        <el-button icon='edit' size="mini" @click='openTeachingClass(scope.row)'>编辑</el-button>
                        <el-button type="danger" icon='edit' size="mini" @click='onDeleteTeachingClass(scope.row)'>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
        <teachingClassDialog ref="teachingClassDialog" :teachingClass="selTeachingClass" @ok="loadTeachingClassList"></teachingClassDialog>
        <teachingHardDialog ref="teachingHardDialog" :teachingClass="selTeachingClass" @ok="loadTeachingClassList"></teachingHardDialog>
        <teachingUploadDialog ref="teachingUploadDialog" :teachingClass="selTeachingClass" @ok="loadTeachingClassList"></teachingUploadDialog>
        <teachingStudentDialog ref="teachingStudentDialog" :teachingClass="selTeachingClass" @ok="loadTeachingClassList"></teachingStudentDialog>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import teachingClassDialog from "./teachingClassDialog";
    import teachingHardDialog from "./teachingHardDialog";
    import teachingUploadDialog from "./teachingUploadDialog";
    import teachingStudentDialog from "./teachingStudentDialog";
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'teachingClassList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false,
                acadyearTermList: [], courseList:[],
                selTeachingClass: null, selTeachingClassDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, courseId: null, acadyearTerm: null},
            }
        },
        components:{
            teachingClassDialog, teachingHardDialog,  teachingUploadDialog, teachingStudentDialog, Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadCourseList();
            this.loadTeachingClassList();
            this.loadAcadyearTermList();
	   },
        methods: {

            loadTeachingClassList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    schoolId: this.userInfo.schoolId, courseId: mUtils.searchText(this.searchForm.courseId),
                    acadyearTerm: mUtils.searchText(this.searchForm.acadyearTerm)};
                let param = { controllerName: 'sclTeachingClass', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            loadCourseList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclCource', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.courseList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-3); i<(year+1); i++){
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
                }
            },
            formatterStatus(row, column, cellValue, index) {

                if(cellValue == '00'){
                    return '正常';
                }else if(cellValue == '01'){
                    return '禁用';
                }
                return '';

            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadTeachingClassList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadTeachingClassList()
            },
            onDeleteTeachingClass(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将永久删除该教师以及明细, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'sclTeachingClass', methodName: '/delete', param: { id: id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadTeachingClassList()
                        }
                        if (code == -3) {
                            that.$message({ type: 'error', message: '该教学班下已有数据，请先清除数据后再删除！'});
                            return ;
                        }
                    });
                }).catch(() => {

                });
            },

            openTeachingClass(row){
                if(row != null){
                    this.selTeachingClass = row;
                }else{
                    this.selTeachingClass = { id: null, licenceId: null, name: null, status: '00', courseId: null, teacherId: null, teahcer02Id: null,
                        schoolId: null, acadyearTerm: null, remark: null, time: (new Date()).getMilliseconds()};
                }
                this.$refs.teachingClassDialog.showDialog();
            },
            openTeachingHard(row){
                if(row != null){
                    this.selTeachingClass = row;
                    this.$refs.teachingHardDialog.showDialog();
                }

            },
            openUpload(row){
                if(row != null){
                    this.selTeachingClass = row;
                    this.$refs.teachingUploadDialog.showDialog();
                }

            },
            studentList(row){
                if(row != null){
                    this.selTeachingClass = row;
                    this.$refs.teachingStudentDialog.showDialog();
                }
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


